import React, { useEffect, useState } from "react";
import axios from "axios";
import SectionArrayLargeCenter from "./News/Section/SectionArrayLargeCenter";
import SectionArrayLargeLeft from "./News/Section/SectionArrayLargeLeft";
import SectionArrayLargeRight from "./News/Section/SectionArrayLargeRight";
import { Col, Row } from "react-bootstrap";
import Toasts from "./Toasts";
import NewsLatestPetitions from "./News/NewsLatestPetitions";
import NewsVideos from "./News/NewsVideos";

const Home = (props) => {
  const [json, setJSON] = useState(null);
  const [jsonKeys, setJSONKeys] = useState(null);
  const [votes, setVotes] = useState(null);
  const [dialog, setDialog] = useState(null);

  const hideDialog = () => setDialog(null);

  const toast = (message) => {
    setDialog(message);
  };

  useEffect(() => {
    const getJSON = async () =>
      axios
        .get("https://api.mobubby.com/data?file=_overview")
        .then((response) => {
          const newJSON = JSON.parse(response.data.content);
          let newJSONKeys = Object.keys(newJSON).sort();
          const indexTrending = newJSONKeys.indexOf("Trending");
          newJSONKeys = newJSONKeys.splice(indexTrending, 1);

          setJSON(newJSON);
          setJSONKeys(newJSONKeys);
          setVotes(response.data.votes);
        });

    getJSON();
  }, []);

  if (!json || !jsonKeys) return null;
  else
    return (
      <>
        <Toasts
          show={!!dialog}
          title="Vote"
          message={dialog}
          onClick={() => hideDialog()}
        />

        <Row>
          <Col>
            <div className="news">
              <div className="mt-3">
                {jsonKeys.map((section, index) => {
                  const articles = json[section]?.[0]
                    ?.map((article) => {
                      return {
                        ...article,
                        url: `https://ourfight.online/news/link/${article.id}`,
                      };
                    })
                    .reduce((a, b) => [...a, b], []);

                  return (
                    <div key={section}>
                      {index === 0 && <NewsLatestPetitions />}
                      {index === 0 && <NewsVideos header="Watch" />}

                      {index % 3 === 0 && (
                        <SectionArrayLargeCenter
                          section={section}
                          articles={articles}
                          showMore={true}
                          votes={votes}
                          toast={toast}
                          {...props}
                        />
                      )}
                      {index % 3 !== 0 && index % 2 === 0 && (
                        <SectionArrayLargeLeft
                          section={section}
                          articles={articles}
                          showMore={true}
                          votes={votes}
                          toast={toast}
                          {...props}
                        />
                      )}
                      {index % 3 !== 0 && index % 2 !== 0 && (
                        <SectionArrayLargeRight
                          section={section}
                          articles={articles}
                          showMore={true}
                          votes={votes}
                          toast={toast}
                          {...props}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
};

export default Home;