const textFilter = (text, wordBounds = true) => {
    if (wordBounds)
      return (text ?? "").replace(
        /\b(ass|ballz|bitch|boob|boobs|bullshit|cock|cunt|damn|dick|fjb|fuck|goddamn|labia|my balls|my nuts|penis|prick|piece of shit|pieces of shit|pussy|shit|testicle|testicles|tit|titty|titties|vagina|vulva)\b/gi,
        "###"
      );
    else
      return (text ?? "").replace(
        /ass|ballz|bitch|boob|boobs|bullshit|cock|cunt|damn|dick|fjb|fuck|goddamn|labia|my balls|my nuts|penis|prick|piece of shit|pieces of shit|pussy|shit|testicle|testicles|tit|titty|titties|vagina|vulva/gi,
        "###"
      );
  };
  
  export default textFilter;