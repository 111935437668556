import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Badge, Col, Row } from "react-bootstrap";
import LayoutArrayLargeCenter from "./Layout/LayoutArrayLargeCenter";

function NewsLatestPetitions(props) {
  const [data, setData] = useState(null);

  const badgeColor = (signatures) => {
    signatures = parseInt(signatures, 10);

    if (signatures >= 250) return "success";
    else if (signatures >= 100) return "info";
    else return "primary";
  };

  const getData = useCallback(async () => {
    await axios
      .get("https://api.ourfight.online/latest")
      .then((response) => setData(response.data));
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  if (!data) return false;

  return (
    <Row>
      <Col>
        <div className="news">
          <LayoutArrayLargeCenter
            hideBallot
            header="Join the Fight!"
            showMore="/latest"
            links={data
              .map((item, index) => {
                return {
                  domain: "https://ourfight.online",
                  url: `/${item.id}`,
                  imageURL:
                    "https://static.ourfight.online/upload/" + item.image,
                  badge: !item.hideProgressBar && item.signatures >= 25 && (
                    <Badge pill bg={badgeColor(item.signatures)}>
                      {item.signatures} Signed!
                    </Badge>
                  ),
                  ...item,
                };
              })
              .reduce((a, b) => [...a, b], [])}
          />
        </div>
      </Col>
    </Row>
  );
}
export default NewsLatestPetitions;